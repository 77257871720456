<template>
  <div class="more" ref="container">
    <div class="nav-btn">
      <router-link
        :to="{
          path: '/lesson/invite',
          query: { admin_kf_id: admin_kf_id, offline_id: offline_id },
        }"
        ><span>客服邀约</span></router-link
      >
      <router-link :to="`/lesson/myInvited`" class="active"
        ><span>邀约记录</span></router-link
      >
    </div>

    <div class="search">
      <div class="item">
        <van-field
          v-model="name"
          label="姓名/电话："
          placeholder="请输入姓名或电话"
        />
      </div>
      <div class="item">
        <label>日期 区间：</label>
        <div class="time">
          <van-cell :value="time" @click="timeShow = true">{{
            time ? time : "选择日期区间"
          }}</van-cell>
          <van-calendar
            v-model="timeShow"
            :min-date="minDate"
            :max-date="maxDate"
            type="range"
            @confirm="onConfirm"
          />
        </div>
      </div>
      <div class="btn-style">
        <!-- <span style="float: left">总数：{{ total }}</span> -->
        <button class="btn-info" @click="clearParams">清除</button>
        <button class="btn-default" @click="searchEvent">搜索</button>
      </div>
    </div>

    <ul
      class="main-container"
      v-infinite-scroll="loadMore"
      infinite-scroll-distance="0"
      infinite-scroll-immediate-check="false"
      v-if="myInviteList.length > 0"
    >
      <li v-for="(item, i) in myInviteList" :key="i">
        <h4>{{ item.nickname }}</h4>
        <div class="line">
          <div>{{ item.bookname }}</div>
          <div>
            支付状态：
            <span v-if="item.status == -1">退款</span>
            <span v-else-if="item.status == 0">待支付</span>
            <span v-else>已支付</span>
          </div>
        </div>
        <!-- 购买人 -->
        <div class="invited-lable">
          <span class="invited-lable-title">购买人</span>
          <span>：</span>
          <span class="invited-lable-content">
            {{ item.real_name }} <span class="slash">/</span>{{ item.phone }}<span  class="slash">/</span>{{ item.card_id }}
          </span>
        </div>

        <!-- 听课人 -->
        <div class="invited-lable">
          <span class="invited-lable-title">听课人</span>
          <span>：</span>
          <span class="invited-lable-content">
            {{ item.name }}<span  class="slash">/</span>{{ item.tel }}<span  class="slash">/</span>{{ item.cert_num }}
          </span>
        </div>
        <!-- 站点 -->
        <div class="invited-lable">
          <span class="invited-lable-title">站点</span>
          <span>：</span>
          <span class="invited-lable-content">
            {{ item.sitename }}
          </span>
        </div>
        <!-- 订单号 -->
        <div class="invited-lable">
          <span class="invited-lable-title">订单号</span>
          <span>：</span>
          <span class="invited-lable-content">
            {{ item.order_id }}
          </span>
        </div>
        <!-- 是否签到 -->
        <div class="invited-lable">
          <span class="invited-lable-title">是否签到</span>
          <span>：</span>
          <span class="invited-lable-content">
            {{ item.status == 1 ? "已签到" : "未签到" }}
          </span>
        </div>
        <!-- 创建时间 -->
        <div class="invited-lable">
          <span class="invited-lable-title">创建时间</span>
          <span>：</span>
          <span class="invited-lable-content">
            {{ item.addtime }}
          </span>
        </div>
      </li>
    </ul>
    <div v-else class="emptyData" v-show="isEmptyData">
      <img src="@assets/images/emptyData.png" />
      <p>暂无数据</p>
    </div>
    <van-loading
      class="van-loading"
      size="24px"
      type="spinner"
      v-show="isLoading"
      >加载中...</van-loading
    >
  </div>
</template>

<script>
import Vue from "vue";
import { Calendar } from "vant";
import { Field } from "vant";

import { myInviteList } from "@api/user";

Vue.use(Calendar);
Vue.use(Field);

export default {
  name: "more",
  components: {},
  data() {
    return {
      name: "",
      time: "",
      timeShow: false,
      minDate: new Date(2021, 0, 1),
      maxDate: new Date(2029, 12, 31),
      myInviteList: [], //邀约记录
      isLoading: true, //加载
      isEmptyData: false, //是否有数据
    };
  },
  created() {},
  mounted() {
    this.admin_kf_id = this.$route.query.admin_kf_id;
    this.offline_id = this.$route.query.offline_id;
    this.getMyInviteList();
  },
  methods: {
    // 获取邀约记录
    getMyInviteList() {
      let that = this;
      let params = {
        keyword: that.name,
        add_time: that.time,
      };
      that.loading = true;
      myInviteList(params).then((res) => {
        that.isLoading = false;
        (that.isEmptyData = true), (that.myInviteList = res.data);
      });
    },

    // 清除搜索条件
    clearParams() {
      let that = this;
      that.isEmptyData = false;
      that.name = "";
      that.time = "";
      that.searchEvent();
    },

    // 搜索
    searchEvent() {
      let that = this;
      that.myInviteList = [];
      that.getMyInviteList();
    },

    // 时间区间选择
    formatDate(time) {
      return `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    },
    onConfirm(time) {
      const [start, end] = time;
      this.timeShow = false;
      this.time = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.more {
  padding-top: 0.88rem;

  .nav-btn {
    background: white;
    height: 0.88rem;
    font-size: 0.26rem;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    a {
      width: 50%;
      text-align: center;
      line-height: 0.88rem;
    }
    .active {
      color: #26a2ff;
      border-bottom: 2px solid #26a2ff;
    }
  }
  // .title {
  //   margin-top: 0.2rem;
  //   font-size: 0.26rem;
  //   line-height: 0.6rem;
  //   padding-left: 0.2rem;
  // }
  .search {
    margin-top: 0.2rem;
    background: white;
    font-size: 0.26rem;
    padding: 0.2rem;
    .item {
      display: flex;
      justify-content: space-between;
      height: 0.8rem;
      line-height: 0.8rem;
      .van-cell {
        padding: unset;
        line-height: unset;
        ::v-deep .van-cell__title {
          margin: unset;
          width: 1.55rem;
          font-size: 13px;
          color: #333;
        }
        ::v-deep .van-cell__value {
          .van-field__control {
            margin: 0.1rem 0;
            padding-left: 15px;
            height: 28px;
            border: 1px solid #b7b7b7;
            border-radius: 4px;
            background-color: white;
            position: relative;
            cursor: pointer;
          }
        }
      }
      label {
        width: 2rem;
      }
      .time {
        width: 100%;
        border: 1px solid #b7b7b7;
        text-align: right;
        border-radius: 4px;
        height: 0.6rem;
        line-height: 0.6rem;
        margin: 0.1rem 0;
        padding-right: 0.1rem;
        van-cell {
          width: 100%;
          color: #26a2ff;
          display: block;
        }
      }
    }
  }
  ul li {
    width: 100%;
    padding: 0.2rem 0.3rem;
    background: white;
    margin-top: 0.2rem;
    .btn {
      text-align: right;
      margin-top: 0.2rem;
      button {
        display: inline-block;
        color: white;
        font-size: 0.24rem;
        padding: 0.1rem 0.3rem;
        border-radius: 4px;
        margin-left: 0.1rem;
      }
    }
    .line {
      font-size: 0.24rem;
      display: flex;
      justify-content: space-between;
      line-height: 0.45rem;
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        span {
          color: #26a2ff;
        }
      }
    }
    .invited-lable {
      display: flex;
      word-break: break-all;
      line-height: 0.38rem;
      &-title {
        display: inline-block;
        width: 1.12rem;
        text-align: justify;
        text-align-last: justify;
        vertical-align: middle;
      }
      &-content {
        flex: 1;
        .slash {
          color: #26A2FF;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
